$(".lang_open a").on('click', function () {
  if ($(this).hasClass('is-active')) {
    $('.lang').addClass('open');
    return false;
  } else {
    $('.lang').removeClass('open');
    return true;
  }
});

$(document).ready(function () {
  $('header .header_wrapper .header_left .lang_open a:not(.is-active)').appendTo('.lang');
});

$(document).ready(function () {

  if ($('#about .wrapper .left .video_vertical').length !== 0) {
    var margin_top_left = $('#about .wrapper .left .video_vertical').height();
  } else {
    var margin_top_left = $('#about .wrapper .left .abs').height();
  }

  margin_top_left = margin_top_left / 2 + 50;

  setTimeout(function () {
    $('#about .wrapper .left').css('margin-top', margin_top_left);
  }, 100);

  setTimeout(function () {
    $('h1').addClass('is-visible');
  }, 1000);

  setTimeout(function () {
    $('.datepicker_form').addClass('is-visible');
  }, 1200);

  setTimeout(function () {
    $('body').addClass('is-active');
  }, 100);

});




/*Language Switcher*/
$(".language.is-active").on('click', function () {
  return false;
});

/*Open Menu*/
$(".menu_open button").on('click', function () {
  if ($(this).hasClass('is-active')) {
    $("body").css('overflow-y', 'scroll');
    $(this).removeClass('is-active');
    $('.headermenu').removeClass('is-active');
    $('.header_wrapper').removeClass('openmenu');
  }
  else {
    $(this).addClass('is-active');
    jQuery("body").css('overflow-y', 'hidden');
    $('.headermenu').addClass('is-active');
    $('.header_wrapper').addClass('openmenu');
  }
});

$(".menu-item-has-children ul li a").on('click', function () {
  var href = $(this).attr('href');
  window.location.href = href;
});

/*Open Sub Menu*/
$(".menu-item-has-children").on('click', function () {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
  } else {
    $(this).addClass('active');
  }
  return false;
});




$(".sub-menu li a").hover(function () {
  $(this).addClass('active');
}, function () {
  $(this).removeClass('active');
});

/*Experience hover*/
$(".experience a").hover(function () {
  $(this).parent().addClass('active');
}, function () {
  $(this).parent().removeClass('active');
});
/*Room Hover*/
$(".room a").hover(function () {
  $(this).parent().addClass('active');
}, function () {
  $(this).parent().removeClass('active');
});

/*Gallery*/
//import Swiper, { Navigation } from 'swiper';
import Swiper, { Navigation } from 'swiper';


new Swiper(".gallery_swipe", {
  slidesPerView: 1.5,
  grabCursor: true,
  spaceBetween: 20
});

const gallery_swi = new Swiper(".hero_slider", {
  slidesPerView: "1",
  centeredSlides: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});


new Swiper(".awards_swipe", {
  slidesPerView: 6,
  spaceBetween: 25,
  centeredSlides: false,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next-aw',
    prevEl: '.swiper-button-prev-aw',
  },
  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    480: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 5,
    },
    991: {
      slidesPerView: 6,
    }
  }
});

/*Datepicker*/
$(document).ready(function () {
  var theLanguage = $('html').attr('lang');
  if (theLanguage == 'el') {
    $('.datepicker').pickadate({
      monthsFull: ['Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάιος', 'Ιούνιος', 'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος'],
      monthsShort: ['Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαι', 'Ιουν', 'Ιουλ', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ'],
      weekdaysFull: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
      weekdaysShort: ['Κυρ', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
      showMonthsShort: true,
      today: 'σήμερα',
      clear: 'Διαγραφή',
      close: 'Cancel',
      format: 'd mmm',
      formatSubmit: 'dd/mm/yyyy',
      hiddenName: true,
      min: new Date(2023,4,4),
      language: 'el',
      onSet: function () {
         var target = this.$node.data('for');
         var d = this.get('select', 'dd');
         var m = this.get('select', 'mmmm');
         var v = this.get('select', 'd mmm');//d/m/yyyy
         var $el = this.$node.parent();
         $el.find('.txt').html(m);
         $el.find('.num').html(d);
         $(target).val(v);
   },
    })
  } else {
    $('.datepicker').pickadate({
      weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      showMonthsShort: true,
      today: 'Today',
      clear: 'Clear',
      close: 'Cancel',
      format: 'd mmm',
      formatSubmit: 'dd/mm/yyyy',
      hiddenName: true,
      min: new Date(2023,4,4),
      language: 'el',
      onSet: function () {
         var target = this.$node.data('for');
         var d = this.get('select', 'dd');
         var m = this.get('select', 'mmmm');
         var v = this.get('select', 'd mmm');//d/m/yyyy
         var $el = this.$node.parent();
         $el.find('.txt').html(m);
         $el.find('.num').html(d);
         $(target).val(v);
   },
    })
  }
});

$("#nights_pick").on('click', ".increase", function () {
  var num = parseInt($(this).parent().text());
  var $span = $(this).parent().find("span");
  var target = $(this).parent().data('for');
  num += 1;
  /* var maxadults_num=3;
   if (num>=maxadults_num) {
     num=maxadults_num;
   }*/
  $(target).val(num);
  $span.text(num);
});
$("#adults_pick").on('click', ".increase", function () {
  var num = parseInt($(this).parent().text());
  var $span = $(this).parent().find("span");
  var target = $(this).parent().data('for');
  num += 1;
  var maxadults_num = 5;
  if (num >= maxadults_num) {
    num = maxadults_num;
  }
  $(target).val(num);
  $span.text(num);
});
$("#children_pick").on('click', ".increase", function () {
  var num1 = parseInt($(this).parent().text());
  var $span = $(this).parent().find("span");
  var target = $(this).parent().data('for');
  num1 += 1;
  var maxchild_num = 4;
  if (num1 >= maxchild_num) {
    num1 = maxchild_num;
  }
  $(target).val(num1);
  $span.text(num1);
});

$(".number_spin").on('click', ".decrease", function () {
  var num = parseInt($(this).parent().text());
  var $span = $(this).parent().find("span");
  var target = $(this).parent().data('for');
  if (num > 1) {
    num -= 1;
    $span.text(num);
    $(target).val(num);
  }

});
$("#children_pick.number_spin").on('click', ".decrease", function () {
  var num = parseInt($(this).parent().text());
  var $span = $(this).parent().find("span");
  var target = $(this).parent().data('for');
  if (num >= 1) {
    num -= 1;
    $span.text(num);
    $(target).val(num);
  }

});

/*Mansonry*/
if ($(window).width() > 768) {
  var $grid = $('.grid').imagesLoaded(function () {
    // init Masonry after all images have loaded
    $grid.masonry({
      itemSelector: '.grid-item',
      gutter: 100,
      horizontalOrder: true
    });
  });
}

/*Video*/
$(document).ready(function () {
  setTimeout(() => {
    if ($('.landing').hasClass('video')) {
      $('.landing video').trigger('play');
    }
    if ($('.left').find('.video_vertical').length !== 0) {
      $('.video_vertical video').trigger('play');
    }

  }, 100);

});

// $(window).on('mousemove', function(e){
// var lSide=$(this).width()/2;
// var cursorImg=e.pageX<lSide ? 'arrow_left' : 'arrow_right';
// $('#featured').css({"cursor":"url('wp-content/themes/ostraco/assets/images/"+cursorImg+".png'), auto"});
// // console.log(cursorImg) // Just to see check it ;)
// });



/*Custom Cursor*/
if ($(window).width() > 768) {

  $("#featured").on('click', function () {
    if ($('.slider_cursor').hasClass('left')) {
      gallery_swi.slidePrev();
    } else {
      gallery_swi.slideNext();
    }
  });


  $(document).mousemove(function () {
    if ($(".datepicker_form:hover").length != 0) {
      $(".slider_cursor").addClass('hide');
    } else {
      $(".slider_cursor").removeClass('hide');
    }
  });

  $("#featured").mousemove(function (e) {
    var relX = event.pageX - $(this).offset().left;
    var relY = event.pageY - $(this).offset().top;
    var header_height = $('.header_wrapper').height() + 50;
    // console.log(relY,header_height);
    var lSide = $(this).width() / 2;

    if (e.pageX < lSide) {
      $(".slider_cursor").addClass('left');
    } else {
      $(".slider_cursor").removeClass('left');
    }
    if (e.pageY < header_height) {
      $(".slider_cursor").addClass('hide_header');
    } else {
      $(".slider_cursor").removeClass('hide_header');
    }

    $(".slider_cursor").css('top', e.pageY);
    $(".slider_cursor").css('left', e.pageX);
  });
}


// jQuery(document).ready(
//     function($)
//     {
//         jQuery("h1, h2, h3, h4, h5, h6, p, span, a, li").each(
//             function()
//             {
//                 if($(this).css('text-transform') == 'uppercase')
//                 {
//                     jQuery(this).html(jQuery(this).html().replace(/[ά]/g,"α"));
//                     jQuery(this).html(jQuery(this).html().replace(/[έ]/g,"ε"));
//                     jQuery(this).html(jQuery(this).html().replace(/[ή]/g,"η"));
//                     jQuery(this).html(jQuery(this).html().replace(/[ύ]/g,"υ"));
//                     jQuery(this).html(jQuery(this).html().replace(/[ώ]/g,"ω"));
//                     jQuery(this).html(jQuery(this).html().replace(/[ί]/g,"ι"));
//                     jQuery(this).html(jQuery(this).html().replace(/[ό]/g,"ο"));
//
//             }
// 			});
//     }
// );


// Submenu mobile
$(document).ready(function () {
  if ($(window).width() <= 991) {
    var offset_s = $('.sub-menu').offset().top;
    offset_s = Math.abs(offset_s);
    var height_s= $('.sub-menu').height();
    var top_s = offset_s - height_s;
    $('.close_sub').css('top',top_s + 'px');


    $("li.menu-item-has-children a").on('click', function () {
        $(this).parent().find('.sub-menu').addClass('active_sub');
        $('.close_sub').addClass('active_sub');
    });

    $(".close_sub").on('click', function () {
          $('.sub-menu').removeClass('active_sub');
          $('.close_sub').removeClass('active_sub');
    });
    
  }
});
