import './modules/script';
import './modules/map/mapalex';

import AOS from 'aos';
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
AOS.init({
  disable: function() {
    var maxWidth = 767;
    return window.innerWidth < maxWidth;
  }
});


import LocomotiveScroll from 'locomotive-scroll';

const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true
});

 $( document ).ready(function() {

   new ResizeObserver(() => scroll.update()).observe(document.querySelector("[data-scroll-container]"));
    //sticky header
    if($('body').hasClass('page-template-tmpl_gallery')){
          $('.header_wrapper').addClass('pinned');
    }else{
      scroll.on('scroll',(instance)=>{
              if(instance.scroll.y > 300){
                  $('.header_wrapper').addClass('pinned');
              }else{
                $('.header_wrapper').removeClass('pinned');
              }
      });
    }


    scroll.on('scroll', (args) => {
        // Get all current elements : args.currentElements
        if(typeof args.currentElements['parall'] === 'object') {
            let progress = args.currentElements['parall'].progress;
            console.log(progress);
            if(progress>0.2){
              $('#parallax').addClass('animation');
            }else{
              $('#parallax').removeClass('animation');
            }
        }
        if(typeof args.currentElements['exper'] === 'object') {
            let progress = args.currentElements['exper'].progress;
            var top_exp=progress*100;
            if(progress>0.1){
                $('#experiences svg').css('top',top_exp+'%');
                $('#experiences svg').css('opacity','0.19');
            }else{
                $('#experiences svg').css('opacity','0');
            }
        }

    });

 })
